import * as React from 'react'
  /* @jsx mdx */
import { mdx } from '@mdx-js/react';
/* @jsxRuntime classic */

/* @jsx mdx */

export const _frontmatter = {
  "title": "「ChatOps 稟議」×「電子契約締結の API 連携」でワークフローの生産性を追求した話",
  "date": "2021-07-16T08:47:40.000Z",
  "slug": "entry/2021/07/16/174740",
  "tags": ["medley"],
  "hero": "./2021_07_16.png",
  "heroAlt": "TS"
};
const layoutProps = {
  _frontmatter
};
const MDXLayout = "wrapper";
export default function MDXContent({
  components,
  ...props
}) {
  return <MDXLayout {...layoutProps} {...props} components={components} mdxType="MDXLayout">
    <h1>{`はじめに`}</h1>
    <p>{`はじめまして、コーポレートエンジニアの山下です。`}</p>
    <p>{`2020 年に`}<a parentName="p" {...{
        "href": "https://slack.com/"
      }}>{`Slack`}</a>{`を活用した ChatOps 稟議ワークフローを内製で開発したのですが、さらに、2021 年 4 月にこの Slack 稟議と電子契約システムである`}<a parentName="p" {...{
        "href": "https://www.cloudsign.jp/"
      }}>{`クラウドサイン`}</a>{`を連携させて、電子契約をもっと便利に使い、生産性の向上を実現しましたのでお話しいたします。`}</p>
    <p>{`まず、当社の稟議システムは`}<a parentName="p" {...{
        "href": "/entry/2020/12/25/180058"
      }}>{`2020 年 12 月の当社の記事`}</a>{`のおさらいになりますが、稟議の作業が Slack 上で完結する、`}<strong parentName="p">{`ChatOps による稟議ワークフロー`}</strong>{`となっております。本稿については 2021 年 7 月に執筆しておりますので丁度導入から 1 年程経過し、その間大きなトラブルも無く、今も当社の極めて迅速な意思決定の一助になっています。ChatOps による稟議ワークフローについては、直近、2021 年 6 月に LayerX 社が`}<a parentName="p" {...{
        "href": "https://layerx.co.jp/news/pr210603"
      }}>{`LayerX ワークフローの新機能として発表`}</a>{`し、サービスとしても提供され、`}<a parentName="p" {...{
        "href": "https://www.nikkei.com/article/DGXZQOUC031TZ0T00C21A6000000/"
      }}>{`日経新聞`}</a>{`でも取り上げられていることから、今現在のパラダイムとして、先進的で有効な一手法であったと再認識しております。`}</p>
    <p>{`今回、新型コロナウイルス感染拡大防止に伴うリモートワークの加速という状況もあり、当社で 2021 年 4 月に電子契約システムとしてクラウドサインを導入しました。電子契約に限らず、契約押印作業は稟議の後続作業に当たるため、ただ導入して使用するのみならず、クラウドサインの API を利用して稟議上にあるデータを電子契約に送信させることでシームレスな連携を実現しています。本稿では当社が行ったシームレスな連携手法について詳細をご説明いたします。`}</p>
    <h1>{`TeamSpirit とクラウドサインの API 連携について`}</h1>
    <h2>{`実装概要`}</h2>
    <p>{`弊社の稟議システムである`}<a parentName="p" {...{
        "href": "https://www.teamspirit.com/"
      }}>{`TeamSpirit`}</a>{`とクラウドサインとの連携についてお話しします。まず、本稿の開発部分とシステム構成は下記になっております。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210715/20210715210552.png",
      "alt": "20210715210552.png"
    }}></img>
    <p>{`処理内容の詳細は後ほど述べますが、概要としては TeamSprit(Apex)からクラウドサインの API をコールし、クラウドサイン上で作成した契約文書へ稟議に記載されている契約書ファイルや先方担当者等の情報を連携する仕組みとなっております。これにより契約担当者はクラウドサインにログイン後、下記の 3 ステップで先方に送信できるようになっています。`}</p>
    <ol>
      <li parentName="ol">{`記載内容の確認`}</li>
      <li parentName="ol">{`押印・署名箇所の設定`}</li>
      <li parentName="ol">{`先方への送信`}</li>
    </ol>
    <p>{`クラウドサインを使用して契約文書を一から作成する場合のユーザ作業と、当社で採用した API 連携行った場合のユーザ作業を比較したものが下記の表です。作業が半分程度削減されたことが分かります。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`作業項番`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`一から作成する場合`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`API 連携を利用した当社の場合`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`1`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ログイン`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ログイン`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`2`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`契約文書の作成(件名、契約文書としての宛名設定等)`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なし`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`3`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`契約書ファイルのアップロード`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なし`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`4`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`先方の送信先設定`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`なし`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`5`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`押印欄の設定`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`押印欄の設定`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`6`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`先方への送信`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`先方への送信`}</td>
        </tr>
      </tbody>
    </table>
    <h1>{`実装`}</h1>
    <p>{`今回の開発で使用した`}<a parentName="p" {...{
        "href": "https://app.swaggerhub.com/apis/CloudSign/cloudsign-web_api/"
      }}>{`クラウドサイン API`}</a>{`は下記の 5 つの API を使用しました
(※以降、クラウドサイン API に倣い、変数を表現する場合は`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`{}`}</code>{`で括ります)。`}</p>
    <table>
      <thead parentName="table">
        <tr parentName="thead">
          <th parentName="tr" {...{
            "align": null
          }}>{`API 種類`}</th>
          <th parentName="tr" {...{
            "align": null
          }}>{`使用用途`}</th>
        </tr>
      </thead>
      <tbody parentName="table">
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`post /token`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`アクセストークンの取得`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`post /document`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`契約文書の作成`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`put /documents/{documentID}/attribute`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`契約文書の作成で設定できない、細かい項目の設定`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`post /documents/{documentID}/files`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`ファイルのアップロード`}</td>
        </tr>
        <tr parentName="tbody">
          <td parentName="tr" {...{
            "align": null
          }}>{`post /documents/{documentID}/participants`}</td>
          <td parentName="tr" {...{
            "align": null
          }}>{`先方の送信先設定`}</td>
        </tr>
      </tbody>
    </table>
    <p>{`全体像で記載したクラウドサインの連携部について、上記の API を織り交ぜて詳細化すると下図のようになります。`}</p>
    <img {...{
      "src": "https://cdn-ak.f.st-hatena.com/images/fotolife/m/medley_inc/20210715/20210715210621.png",
      "alt": "20210715210621.png"
    }}></img>
    <p>{`実装方法としてはクラウドサイン API のリファレンスを参照し、テスト実行時に出力される`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`curl`}</code>{`コマンドを参考に同様のレスポンスを得るように Apex で HTTP リクエストを実装しました。アクセストークンの取得を例にとると下記のようになります。`}</p>
    <h4>{`API リファレンスでの curl コマンド例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'POST'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://api.cloudsign.jp/token'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-H `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'accept: application/json'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-H `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Content-Type: application/x-www-form-urlencoded'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-d `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'client_id=xxxxxxyyyyyyzzzzzz'`}</span></code></pre></div>
    <h4>{`Apex でのリクエスト実装例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`HttpRequest`}</span>{` req `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HttpRequest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setMethod`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`'POST'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setEndpoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`api`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cloudsign`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`jp`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`token'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`'accept'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 'application`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`json'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Content`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Type`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`', '`}</span>{`application`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`x`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`www`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`urlencoded'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setBody`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'client_id`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`' + '`}</span>{`xxxxxxyyyyyyzzzzzz'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span></code></pre></div>
    <p>{`私自身、Apex もクラウドサイン API もこの案件を担当するまで触ったことがありませんでしたが、リクエストの試行から実装まで 2 週間かからない程度で実装することができました。`}</p>
    <p>{`ただし、実装や運用にあたっては下記 2 点について注意が必要になります。`}</p>
    <ol>
      <li parentName="ol">{`Apex からクラウドサインへのファイルのアップロードは単純ではない`}</li>
      <li parentName="ol">{`アクセストークンの有効期限はクラウドサインでコントロールされる`}</li>
    </ol>
    <h2>{`1. Apex からクラウドサインへのファイルのアップロードは単純ではない`}</h2>
    <p>{`ファイルのアップロードについては今回使用した API の中で、唯一、テスト実行の`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`curl`}</code>{`と Apex のリクエスト実装で差分が生まれます。まず、その差分を確認するために curl コマンド例と Apex のリクエスト実装例で header、body にセットしている値を比較してみます。`}</p>
    <h4>{`API リファレンスでの curl コマンド例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "shell"
    }}><pre parentName="div" {...{
        "className": "language-shell"
      }}><code parentName="pre" {...{
          "className": "language-shell"
        }}><span parentName="code" {...{
            "className": "token function"
          }}>{`curl`}</span>{` -X `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'POST'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
`}<span parentName="code" {...{
            "className": "token string"
          }}>{`'https://api.cloudsign.jp/documents/{document_id}/files'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-H `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'accept: application/json'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-H `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Authorization: AAAAAABBBBBBCCCCCC'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-H `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'Content-Type: multipart/form-data'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-F `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'name=テスト'`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`\\`}</span>{`
-F `}<span parentName="code" {...{
            "className": "token string"
          }}>{`'uploadfile=@テスト.pdf;type=application/pdf'`}</span></code></pre></div>
    <h4>{`Apex でのリクエスト実装例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token class-name"
          }}>{`HttpRequest`}</span>{` req `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HttpRequest`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setMethod`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`'POST'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
     req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setEndpoint`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'https`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`api`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`cloudsign`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span>{`jp`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`documents`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`document_id`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`files'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`'accept'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` 'application`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`json'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Authorization`}</span>{`'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` ‘AAAAAABBBBBBCCCCCC’`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setHeader`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'`}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Content`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Type`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`', '`}</span>{`multipart`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`/`}</span>{`form`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`-`}</span>{`data`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{` boundary`}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`boundary`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ※1`}</span>{`
req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`setBodyAsBlob`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`multipartBody`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`  `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`// ※2`}</span></code></pre></div>
    <p>{`主な違いは `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`※1`}</code>{`, `}<code parentName="p" {...{
        "className": "language-text"
      }}>{`※2`}</code>{` とコメントした部分になります。`}</p>
    <p>{`Apex では HTTP リクエストの値を手で書いていくことになるので、テスト実行例のように`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`curl`}</code>{`がよしなに処理している部分(-F オプションの部分や Apex で記載している boundary)も実装しなければなりません。これが単純に実装できない理由になります。`}</p>
    <p>{`boundary については multipart/form-data を送信する際に必要な境界でヘッダーでどの文字列が境界であるかを設定します。`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`curl`}</code>{`の-F オプションで定義していた文字列とファイル指定部分は、Apex でファイル(バイナリ)を扱うため、その body に含まれる文字列も含めて Blob 型で扱う必要があります(`}<code parentName="p" {...{
        "className": "language-text"
      }}>{`Content-Transfer-Encoding: base64`}</code>{`に API 提供側が対応している場合は例外になります)。そのため、文字列とバイナリデータを結合し一つの Blob にする方法は下記になります。`}</p>
    <ol>
      <li parentName="ol">{`「バイナリデータ」、「body の開始からバイナリデータまでの文字列」、「バイナリデータ以降から終端までの文字列」の 3 グループに分ける。`}</li>
      <li parentName="ol">{`3 グループをそれぞれ`}<a parentName="li" {...{
          "href": "https://ja.wikipedia.org/wiki/Base64"
        }}>{`Base64`}</a>{`で符号化する。`}</li>
      <li parentName="ol">{`符号化した「バイナリデータ」と「body の開始からバイナリデータまでの文字列」について、Base64 のデータパディングを示す”=”が含まれないように改行コードで調整する。`}</li>
      <li parentName="ol">{`「body の開始からバイナリデータまでの文字列」、「バイナリデータ」、「バイナリデータ以降から終端までの文字列」の順で結合する。`}</li>
      <li parentName="ol">{`結合した Base64 のデータを復号して、一つの Blob とする。`}</li>
    </ol>
    <h2>{`2. アクセストークンの有効期限はクラウドサインでコントロールされる`}</h2>
    <p>{`アクセストークンやその有効期限は token API を発行した際のレスポンスとしてクラウドサインから発行されます。`}</p>
    <h4>{`発行されたレスポンス例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"access_token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"AAAAAABBBBBBCCCCCC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` “xxxx”`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"expires_in"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3600`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`このレスポンスの内、expires_in の値がトークンの有効期限になります。掲題の通り、有効期限の管理はクラウドサイン側で行われ、有効期限内に再度トークンのリクエストを行った場合、経過した時間だけ expires_in の値が小さくなった結果が返ってきて、access_token などは同じ値が取得されます。有効期限内に token API を再度実行した結果が下記になります。`}</p>
    <h4>{`有効期限切れ前に token API を発行した際のレスポンス例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"access_token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"AAAAAABBBBBBCCCCCC"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` “xxxx”`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
     `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"expires_in"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`762`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`一方、有効期限後にトークンのリクエストを実行すると、それまでと異なるアクセストークンを取得し、新しい有効期限が設定されます。`}</p>
    <h4>{`有効期限切れ後に token API を発行した際のレスポンス例`}</h4>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "json"
    }}><pre parentName="div" {...{
        "className": "language-json"
      }}><code parentName="pre" {...{
          "className": "language-json"
        }}><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"access_token"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"XXXXXXYYYYYYZZZZZZ"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"token_type"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token string"
          }}>{`"xxxx"`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{`
  `}<span parentName="code" {...{
            "className": "token property"
          }}>{`"expires_in"`}</span><span parentName="code" {...{
            "className": "token operator"
          }}>{`:`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`3600`}</span>{`
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <p>{`そのため、API 連携が一度動いた後、有効期限ぎりぎりでもう一度 API 連携が動いてしまった場合、タイミングが悪いと契約文書の作成から最終処理である先方の送信先設定までのプロセス内のどこかから、トークンの有効期限切れが発生する可能性が想定されます。実際に期限切れが発生した場合、発生時以降に発行したその回の API 連携処理が失敗します。`}</p>
    <p>{`トークンの有効期限切れが発生した際、API リファレンスより HTTP ステータスコードが 401 かつエラー内容が”unauthorized”で応答されることから、当社ではこのエラーを受けた場合にトークンを再取得して処理をリトライするように実装しました。`}</p>
    <p>{`押印文書作成を例にとると下記のような実装イメージになります。`}</p>
    <div {...{
      "className": "gatsby-highlight",
      "data-language": "java"
    }}><pre parentName="div" {...{
        "className": "language-java"
      }}><code parentName="pre" {...{
          "className": "language-java"
        }}><span parentName="code" {...{
            "className": "token comment"
          }}>{`//クラウドサイン上に押印文書を作成し、作成した文書 ID を取得する`}</span>{`
`}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`public`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getDocumentId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` authToken`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`

    ・・・中略・・・

    `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`HTTPResponse`}</span>{` res `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` http`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`send`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`req`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getStatusCode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`200`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        ・・・正常に終了した際の処理・・・
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`

    `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//タイミングが悪く token がタイムアウトした場合、トークンを取得し直して、リトライする`}</span>{`
    `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`else`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getStatusCode`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{` `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`==`}</span>{` `}<span parentName="code" {...{
            "className": "token number"
          }}>{`401`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//レスポンスの内容を確認するため、エラーレスポンスの中身を取得する`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Map`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Object`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span>{` responseBody `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`new`}</span>{` `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`Map`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Object`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        responseBody `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`Map`}</span><span parentName="code" {...{
            "className": "token generics"
          }}><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`<`}</span><span parentName="span" {...{
              "className": "token class-name"
            }}>{`String`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`,`}</span>{` `}<span parentName="span" {...{
              "className": "token class-name"
            }}>{`Object`}</span><span parentName="span" {...{
              "className": "token punctuation"
            }}>{`>`}</span></span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`JSON`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`deserializeUntyped`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`res`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`getBody`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span>{` errorVal `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token class-name"
          }}>{`String`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span>{`responseBody`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`get`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token char"
          }}>{`'error'`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`

        `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//リファレンス上、アクセストークンが無効(有効期限切れ)の場合、'unauthorized’となる`}</span>{`
        `}<span parentName="code" {...{
            "className": "token keyword"
          }}>{`if`}</span>{` `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`errorVal`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`.`}</span><span parentName="code" {...{
            "className": "token function"
          }}>{`equals`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`'unauthorized'`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`{`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//クラウドサインのアクセストークンの再取得`}</span>{`
            authToken `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getAuthToken`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
            `}<span parentName="code" {...{
            "className": "token comment"
          }}>{`//単純再帰で再実行する。`}</span>{`
            documentId `}<span parentName="code" {...{
            "className": "token operator"
          }}>{`=`}</span>{` `}<span parentName="code" {...{
            "className": "token function"
          }}>{`getDocumentId`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`(`}</span>{`authToken`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` title`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`,`}</span>{` message`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`)`}</span><span parentName="code" {...{
            "className": "token punctuation"
          }}>{`;`}</span>{`
        `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
        ・・・中略・・・
    `}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span>{`
    ・・・以下省略・・・
`}<span parentName="code" {...{
            "className": "token punctuation"
          }}>{`}`}</span></code></pre></div>
    <h1>{`実装を終えて`}</h1>
    <p>{`上記を実装した結果、稟議と入力内容が同じ、または、稟議から生成できる内容は全てシステム連携で自動生成するため、押印担当は稟議とクラウドサインの画面を並べて転記するような煩雑な作業を必要としない環境になりました。また、契約書の製本、郵送等の紙媒体であるが故の事務の削減ができるようになる等の、電子契約を導入することのそもそものメリットも併せて享受しています。`}</p>
    <p>{`当社では 2021 年 4 月後半からクラウドサインを導入しましたが、2021 年 6 月時点ではすでに`}<strong parentName="p">{`月間で締結した契約書の「3 割以上」が電子契約を活用しており`}</strong>{`、押印担当の展望として今後も利用を拡大していく予定です。`}</p>
    <h1>{`コーポレートエンジニア募集中`}</h1>
    <p>{`メドレーのコーポレート部門では、本稿のように、SaaS の導入ひとつとっても検討を尽くし、既存のシステムと有機的に結合させることで「徹底的に合理性を追求した組織基盤や、仕掛けづくり」を行っています。`}</p>
    <p>{`面白そう！興味がある！と感じた方は、ぜひ当社採用ページからご応募お願いします！`}</p>
    <p>{`最後までお読みいただきありがとうございました。`}</p>
    <p><a parentName="p" {...{
        "href": "https://www.medley.jp/jobs/"
      }}>{`https://www.medley.jp/jobs/`}</a></p>

    </MDXLayout>;
}
;
MDXContent.isMDXComponent = true;
      